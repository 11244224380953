import React, { useState, useEffect, useMemo } from 'react';
import axios from "axios";
import totpGenerator from "totp-generator";
import QRCode from "qrcode.react";


function MFAView() {
    const [secret, setSecret] = useState("JBSWY3DPEHPK3PXP");  // For now, this isn't configurable
    const [localTimeCorrect, setLocalTimeCorrect] = useState<boolean | undefined>(undefined);
    const [time, setTime] = useState(Date.now());

    // Send off a request to get a trusted time and check this device is relatively close
    useEffect(() => {
        const f = async () => {
            const resp = await axios.get("https://tg6gorfuc2foo6ptlcgj7tkdpy0psyzb.lambda-url.ap-southeast-2.on.aws/");
            const serverUnix = resp.data.unixTimeMilliseconds / 1000;
            const browserUnix = Date.now() / 1000;
            setLocalTimeCorrect(Math.abs(serverUnix - browserUnix) < 10);
        };
        f();
    }, []);

    // Tick the current device time very second
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    // Calculate the timestamp in units of 30s since epoch
    const timeUnits = Math.floor(time / 30);

    // Calculate the TOTP, update every timeunit
    const totp = useMemo(() => totpGenerator(secret), [secret, timeUnits]);

    const clockSyncMessage = useMemo(() => {
        if (localTimeCorrect === undefined) return <div className="icon-row"><img src="/icons8-round-48.png" width="24" height="24" />Trying to validate your computer's clock, please wait...</div>;
        if (localTimeCorrect) return <div className="icon-row"><img src="/icons8-check-circle-48.png" width="24" height="24" />Your computer's clock is correct, move to next step</div>;
        return <div className="icon-row"><img src="/icons8-cancel-48.png" width="24" height="24" />Your computer's clock is incorrect and this tool won't work, please fix your computer's time</div>;
    }, [localTimeCorrect]);

    return (
        <>
            <p>This tool can help find issues with your TOTP MFA device (such as a phone using an authenticator app).</p>
            <h2>Check computer time</h2>
            <p>{clockSyncMessage}</p>
            <h2>Test Device</h2>
            <p>Scan the below QR code with your MFA device / app</p>
            <QRCode value={`otpauth://totp/testing?secret=${secret}`} size={120} />
            <p>Check that the code showing up on your device matches the code below:</p>
            <p><code>{totp}</code></p>
            <p>If the codes match, your MFA device / app is working correctly.<br />If they don't match, you will need to check your device's time and timezone settings are correct.</p>
        </>
    );
}

export default MFAView;
