import React from 'react';
import './App.css';
import { Link, Route, Routes } from 'react-router-dom';
import HomeView from "./HomeView";
import SystemAndConnectivityView from "./SystemAndConnectivityView";
import MFAView from "./MFAView";

function App() {
  return (
    <>
      <header className="header">
        <div className="header-content">
          <Link to="/"><h1>Self-service Tools</h1></Link>
          <div className="flex-1" />
          <img src="/poly-logo.png" className="logo" />
        </div>
      </header>
      <div className="content">
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/sys-connect-test" element={<SystemAndConnectivityView />} />
          <Route path="/mfa" element={<MFAView />} />
        </Routes>
      </div>
      <footer>
        <div><a href="https://polymathian.com">Polymathian</a> - Industrial Mathematics</div>
        <div>Some icons under license from <a href="https://icons8.com" rel="noopener noreferrer">Icons8</a></div>
      </footer>
    </>
  );
}

export default App;
