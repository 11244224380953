import React from 'react';
import axios from "axios";
import { OrderedMap } from 'immutable';

enum TestResult {
    PENDING, RUNNING, PASS, FAIL
}

type Test = {
    name: string;
    callable: () => Promise<void>;
}

const tests: Array<Test> = [
    // {
    //     name: "Connection to staging product server",
    //     callable: async () => {
    //         await axios.get("https://testing.polymathian.com/class_construction");
    //     }
    // },
    // {
    //     name: "Connection to production product server",
    //     callable: async () => {
    //         await axios.get("https://apps.polymathian.com/class_construction");
    //     }
    // },
    {
        name: "Connection to CDN",
        callable: async () => {
            await axios.get("https://appstatic.polymathian.net/connectivity-check.json");
        }
    }
]


type ResultLineProps = {
    name: string;
    result: TestResult;
}

function ResultLine(props: ResultLineProps) {
    const iconSrc = (() => {
        switch (props.result) {
            case TestResult.PENDING:
            case TestResult.RUNNING:
                return "/icons8-round-48.png";
            case TestResult.PASS:
                return "/icons8-check-circle-48.png";
            default:
                return "/icons8-cancel-48.png";
        }
    })();
    return <div className="icon-row"><img src={iconSrc} width="24" height="24" />{props.name}</div>
}

function createDefaultResultsMap() {
    return OrderedMap(tests.map((test) => [test.name, TestResult.PENDING]));
}

function SystemAndConnectivityView() {
    const [isRunning, setIsRunning] = React.useState(false);
    const [results, setResults] = React.useState(createDefaultResultsMap);  // XXX: Mutable object

    const doTest = React.useCallback(async () => {
        setIsRunning(true);
        setResults(createDefaultResultsMap);
        
        try {
            for (const test of tests) {
                    setResults((prev) => prev.set(test.name, TestResult.RUNNING));
                    try {
                        await test.callable();
                        setResults((prev) => prev.set(test.name, TestResult.PASS));
                    } catch {
                        setResults((prev) => prev.set(test.name, TestResult.FAIL));
                    }
            }
        } finally {
            setIsRunning(false);
        }
    }, []);

    return (
        <>
            <p>This test checks some basic minimum requirements to use Polymathian products.</p>
            <p>Click the button to start the test.</p>
            <button disabled={isRunning} onClick={doTest}>{isRunning ? "Running" : "Run Test"}</button>

            {
                Array.from(results).map(([name, result]) => <ResultLine key={name} name={name} result={result} />)
            }
        </>
    );
}

export default SystemAndConnectivityView;
