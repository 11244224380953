import React from 'react';
import { Link } from 'react-router-dom';

function HomeView() {
    return (
        <>
            <p>This site contains a set of self-service tools to assist with debugging hardware and network issues.</p>
            <p>Seek support from your Polymathian contact if you're encountering issues.</p>
            <h2>Tools</h2>
            <ul>
                <li><Link to="/sys-connect-test">System Requirements &amp; Connectivity Test</Link></li>
                <li><Link to="/mfa">TOTP MFA Debugger</Link></li>
            </ul>
        </>
    );
}

export default HomeView;
